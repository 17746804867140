<template>
    <div class="container-pc">
        <div class="myinfo-view Global-W">
            <div class="myinfo-title f-w">Basic Information</div>

            <div class="myinfo-info">
                <div class="myinfo-info-view">
                    <div class="myinfo-info-info">
                        <div class="myinfo-info-title">My Information</div>
                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">Name：</div>
                            <el-input v-model="userBaseinfo.nickname" class="login-input" clearable />
                        </div>
                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">Email：</div>
                            <el-input v-model="userBaseinfo.email" class="login-input" clearable :disabled="true" />
                        </div>
                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">Phone number：</div>
                            <el-input placeholder="Phone number" v-model="userBaseinfo.mobile" class="login-value"
                                style="width: 350px;" clearable>
                                <template #prepend>
                                    <el-select v-model="userBaseinfo.country" placeholder="Area code"
                                        style="width: 150px;" @change="handleOnChangeArea">
                                        <el-option :label="countryItem.label" :value="countryItem.value"
                                            v-for="(countryItem, index) in countryData"></el-option>
                                    </el-select>
                                </template>
                            </el-input>
                        </div>

                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">Birthday：</div>
                            <el-date-picker v-model="userBaseinfo.birthday" format="YYYY-MM-DD" type="date"
                                style="width: 350px;" placeholder="Please enter your birthday"
                                @change="handleOnchangeDay">
                            </el-date-picker>
                        </div>

                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">Gender：</div>
                            <el-select v-model="userBaseinfo.gender" placeholder="Please select your gender"
                                style="width: 350px;">
                                <el-option v-for="item in sixOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>


                    </div>
                </div>
            </div>
            <div class="myinfo-submit-view">
                <div class="myinfo-submit CURSOR" @click="handleSubmitInfo">Save </div>

            </div>
        </div>
    </div>
    <div class="container-m">
        <div class="login-m-view">
            <div class="login-m-title f-w">Basic Information</div>
            <div class="login-m-tips f-w">

            </div>
            <div class="login-m-input-item">
                <div class="login-label">Name</div>
                <input type="text" class="login-value" v-model="userBaseinfo.nickname" placeholder="Name">
            </div>

            <div class="login-m-input-item">
                <div class="login-label">Email</div>
                <input type="text" class="login-value" v-model="userBaseinfo.email" placeholder="Email">
            </div>


            <div class="login-m-input-item">
                <div class="login-label">Phone number</div>
                <input type="text" v-model="userBaseinfo.mobile" class="login-value" placeholder="Phone number">
            </div>

            <div class="login-m-input-item">
                <div class="login-label">Gender</div>
                <input type="text" v-model="userBaseinfo.gender" class="login-value"
                    placeholder="Please select your gender" @click="handleShowPicker('isShowPicker')">
            </div>
            <div class="login-m-input-item">
                <div class="login-label">Birthday</div>
                <input type="text" v-model="userBaseinfo.birthday" class="login-value"
                    placeholder="Please enter your birthday" @click="handleShowPicker('isShowDatePicker')">
            </div>

            <van-button class="login-button" block type="primary" color="#414141" native-type="submit"
                @click="handleSubmitInfo">Save</van-button>

            <van-button class="login-button" block type="primary" color="#414141" native-type="submit"
                @click="handleLoginOut">Login Out</van-button>
        </div>
    </div>

    <van-popup v-model:show="isShowPicker" round position="bottom" :style="{ height: '50%' }">
        <van-picker title="gender" :columns="sixOptions" @confirm="handleOnConfirm" @cancel="handleOnCancel"
            :columns-field-names="{ text: 'label' }" />
    </van-popup>

    <van-popup v-model:show="isShowDatePicker" round position="bottom" :style="{ height: '50%' }">
        <van-date-picker v-model="currentDate" title="选择日期" :min-date="minDate" type="datetime"
            @click="handleOnConfirmDate" @cancel="handleOnCancel" />
    </van-popup>
</template>



<script setup name="MyInfo">
import requestAsync from "@/utils/request";
import { ref, onMounted } from "vue";
import { ElMessage } from 'element-plus'
// import { useRoute, useRouter } from "vue-router";
import countryData from "@/utils/countryData";
import { useStore } from "vuex";
import Cookies from 'js-cookie'

const store = useStore();

const isShowPicker = ref(false)
const isShowDatePicker = ref(false)
const currentDate = ref(['2024', new Date().getMonth() + 1, new Date().getDate()]);
const minDate = new Date(1900, 0, 1)
const menberData = ref({
    email: '',
    six: '',
    date: ''
})

const sixOptions = ref([{
    value: 'm',
    label: 'Male',
}, {
    value: 'f',
    label: 'Female',
}])

onMounted(() => {
    GetUserInfo()
})

const userBaseinfo = ref({})

const GetUserInfo = async () => {
    const data = await requestAsync({
        url: '/userBaseinfo/info'
    })
    console.log('GetUserInfo', data)
    userBaseinfo.value = data.data.userBaseinfo
}

const handleSubmitInfo = async () => {
    if (!(userBaseinfo.value.mobile.includes('+'))) {
        if (userBaseinfo.value.country) {
            userBaseinfo.value.mobile = '+' + userBaseinfo.value.country + userBaseinfo.value.mobile
        }
    }
    const data = await requestAsync({
        url: '/userBaseinfo/updateInfo',
        methods: 'post',
        data: {
            nickname: userBaseinfo.value.nickname,
            mobile: userBaseinfo.value.mobile,
            email: userBaseinfo.value.email,
            birthday: userBaseinfo.value.birthday,
            sex: userBaseinfo.value.gender,
            country: userBaseinfo.value.country,
        }
    })
    console.log('handleSubmitInfo', data)
    if (data.code == 200) {
        ElMessage.success({
            message: 'Personal information modified successfully',
            type: 'success',
        })
        GetUserInfo()
    } else {
        ElMessage.error(data.msg)
    }
}

// const route = useRoute()
// const router = useRouter()

const handleShowPicker = (type) => {
    if (type == 'isShowPicker') {
        isShowPicker.value = !isShowPicker.value
    }
    if (type == 'isShowDatePicker') {
        isShowDatePicker.value = !isShowPicker.value
    }
}

const handleOnConfirm = (selectedValues) => {
    userBaseinfo.value.gender = selectedValues.selectedOptions[0].value
    isShowPicker.value = false
}

const handleOnConfirmDate = () => {
    userBaseinfo.value.birthday = currentDate.value[0] + '-' + currentDate.value[1] + '-' + currentDate.value[2]
    isShowDatePicker.value = false
}

const handleOnCancel = () => {
    isShowPicker.value = false
    isShowDatePicker.value = false
}


const FormatDate = (date) => {
    const year = date.getFullYear();
    // getMonth 返回的月份是从0开始的，因此需要加1
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // 使用ES6模板字符串返回格式化的日期
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

const handleOnchangeDay = () => {
    userBaseinfo.value.birthday = FormatDate(userBaseinfo.value.birthday)
}

const handleOnChangeArea = () => {
    userBaseinfo.value.mobile = ''
}

const handleLoginOut = () => {
    Cookies.remove('uid')
    Cookies.remove('token')
    localStorage.removeItem('token')
    store.dispatch('login', false)
    router.replace({
        name: 'Login'
    })
}



</script>


<style lang="scss" scoped>
:deep() {}

.container-pc {

    .myinfo-view {
        padding-bottom: 40px;
    }

    .myinfo-title {
        widows: 100%;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #f5f6f7;
    }

    .myinfo-info {

        .myinfo-tips {
            width: 100%;
            height: 50px;
            line-height: 50px;
            color: #999;
            font-size: 15px;
        }

        .myinfo-info-view {
            display: flex;
            justify-content: space-between;

            .myinfo-info-info {
                width: 50%;

                .myinfo-info-title {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    border-bottom: 1px solid #f5f6f7;
                    margin: 20px 0;
                }

                .myinfo-item-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .myinfo-item-label {
                        width: 120px;
                        font-size: 15px;
                    }

                    .login-input {
                        width: 350px;
                    }
                }
            }
        }
    }

    .myinfo-submit-view {
        display: flex;
        width: 100%;
    }

    .myinfo-submit {
        width: 200px;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        background-color: #414141;
        color: #fff;
        border-radius: 5px;
        text-align: center;
    }
}


.container-m {
    .login-m-view {
        width: 100%;
        margin: 0 auto;
        padding: 0 .3rem;

        .login-m-title {
            width: 100%;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            font-size: .3rem;
        }

        .login-m-tips {
            font-size: .25rem;
            margin: .2rem 0;
            text-align: center;
        }

        .login-m-state {
            display: flex;
            align-items: center;
            width: 100%;
            height: 1rem;
            font-size: .3rem;

            div {
                margin-right: .2rem;
            }
        }

        .login-m-input-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: .8rem;
            margin: .2rem auto;
            border-bottom: 1px solid #f1f1f1;

            .login-label {
                width: 2rem;
                line-height: .8rem;
                text-align: center;
                font-size: .25rem;
            }

            .login-value {
                width: 4rem;
                font-size: .25rem;
                padding-left: .3rem;
                border: none;
            }
        }

        .login-button {
            margin: .4rem 0;
        }



    }


}
</style>